export default {
  START: 'Start:',
  'pre-sale': '25.09.2023 pre-sale',
  'от 8 до 14 месяцев': '8 to 14 months',
  'First payment': 'First payment:',
  'от $5 000': 'from $5 000',
  'about project': 'about project',
  'PROJECT_DESCRIPTION': 'Oasis by PARQ is a modern complex with the best infrastructure in Bali, located on 37 hectares, which will include 560 infrastructure facilities, namely:',
  villas: 'villas',
  apartments: 'apartments',
  'guest houses': 'guest houses',
  ///////////////////////////////////////////
  'guest house': 'guest house',
  'Payment is made in cryptocurrency': '*Payment is made in cryptocurrency',
  'Area:': 'Area:',
  'Area': 'Area',
  '1-3 floor of 3': '1-3 floor of 3',
  'Ceiling height': 'Ceiling height:',
  'Laundry': 'Laundry',
  'Garden': 'Garden',
  'Description': 'Description:',
  'GUEST_HOUSE_DESCRIPTION': 'The ideal small-sized housing format for tourists. The finish of the facade contrasts beautifully with the surrounding greenery. the surrounding greenery.',
  'Investment scheme': 'Investment scheme',
  'Minimum entry from $5 000 - 1/7th of the cost of a guest house': 'Minimum entry from $5 000 - 1/7th of the cost of a guest house',
  '1st floor': '1st floor',
  'Private pool': 'Private pool',
  'Private entrance': 'Private entrance',
  'Minimum admission is 10% of the value of the dwelling': 'Minimum admission is 10% of the value of the dwelling',
  'Plot area': 'Plot area',
  'VILLA_150_DESCRIPTION': 'Elegant medium-sized housing format, where the facade finishes contrast spectacularly with the surrounding greenery.',
  villa: 'villa',
  VILLA_100_DESCRIPTION: 'Elegant medium-sized housing format, where the facade finishes contrast spectacularly with the surrounding greenery.',
  '1 living room': '1 living room',
  '2 bedrooms': '2 bedrooms',
  'Contact Us': 'Contact Us',
  'Phone': 'Phone',
  'Email': 'Email',
  '6-8 p.': '6-8 p.',
  'Placement': 'Placement',
  '3 bedrooms': '3 bedrooms',
  'VILLA_200_DESCRIPTION': 'Elegant medium-sized housing format, where the facade finishes contrast spectacularly with the surrounding greenery.',
  '3 bedrooms and cabinet': '3 bedrooms and cabinet',
  'invest and profit': 'invest and profit',
  'passive income': 'passive income',
  'annual passive income from long-term renting out': 'annual passive income from long-term renting out',
  'annual passive income from daily rentals': 'annual passive income from daily rentals',
  'expected price increase after completion of construction': 'expected price increase after completion of construction',
  'Why choose Bali for property investment': 'Why choose Bali for property investment',
  'Residential complex': 'Residential complex',
  'Real estate': 'Real estate',
  'Price': 'Price',
  'Apartments': 'Apartments',
  'bedroom': 'bedroom',
  'bedrooms': 'bedrooms',
  'Villa': 'Villa',
  'Layouts': 'Layouts:',
  'Terrace': 'Terrace',
  'Installment plan': 'Installment plan:',
  GUEST_HOUSE_INVESTMENT_DESCRIPTION: 'Minimum entry from $5 000 - 1/7th of the cost of a guest house',
  APARTMENTS_INVESTMENT_DESCRIPTION: 'Minimum admission is 10% of the value of the dwelling',
  VILLA_INVESTMENT_DESCRIPTION: 'Minimum admission is 10% of the value of the dwelling',
  'ROI:': 'ROI:',
  ABOUT_ESTATE_GUEST_HOUSES: 'guest houses',
  '2nd floor': '2nd floor',
  '2nd floor of 3': '2nd floor of 3',
  apartment: 'apartment',
  '3rd floor': '3rd floor',
  '3rd floor of 3': '3rd floor of 3',
  '1st floor of 3': '1st floor of 3',
}