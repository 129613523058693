export default {
  START: 'Inizio:',
  'pre-sale': 'pre-vendita dal 25.09.2023',
  'от 8 до 14 месяцев': 'da 8 a 14 mesi',
  'First payment': 'A partire da:',
  'от $5 000': '$5 000',
  'about project': 'informazioni sul progetto',
  'PROJECT_DESCRIPTION': 'Oasis by PARQ è un complesso moderno con la migliore infrastruttura a Bali, situato su 37 ettari, che includerà 560 strutture immobiliari, precisamente:',
  villas: 'ville',
  apartments: 'appartamenti',
  'guest houses': 'guest houses',
  'guest house': 'guest house',
  'Payment is made in cryptocurrency': '*Il pagamento avviene in criptovaluta',
  'Area:': 'Superficie:',
  'Area': 'Superficie',
  '1-3 floor of 3': '1-3 piano di 3',
  'Ceiling height': 'Altezza del soffitto:',
  'Laundry': 'Lavanderia',
  'Garden': 'Giardino',
  'Description': 'Descrizione:',
  'GUEST_HOUSE_DESCRIPTION': 'Il formato di alloggio ideale per i turisti. La finitura della facciata contrasta splendidamente con la vegetazione circostante.',
  'Investment scheme': 'Schema di investimento',
  'Minimum entry from $5 000 - 1/7th of the cost of a guest house': 'Ingresso minimo a partire da 5000$ - 1/7 del costo di una Guest House',
  '1st floor': '1 piano',
  'Private pool': 'Piscina privata',
  'Private entrance': 'Ingresso indipendente',
  'Minimum admission is 10% of the value of the dwelling': 'Ingresso minimo è il 10% del costo di un Appartamento',
  'Plot area': 'Superficie del terreno',
  'VILLA_150_DESCRIPTION': 'Elegante formato di alloggio di medie dimensioni, dove la finitura della facciata contrasta spettacolarmente con la vegetazione circostante.',
  villa: 'villa',
  VILLA_100_DESCRIPTION: 'Elegante formato di alloggio di medie dimensioni, dove la finitura della facciata contrasta spettacolarmente con la vegetazione circostante.',
  '1 living room': '1 soggiorno',
  '2 bedrooms': '2 camere da letto',
  'Contact Us': 'Contattaci',
  'Phone': 'Telefono',
  'Email': 'E-mail',
  '6-8 p.': '6-8 persone',
  'Placement': 'Collocamento',
  '3 bedrooms': '3 camere da letto',
  'VILLA_200_DESCRIPTION': 'Elegante formato di alloggio di medie dimensioni, dove la finitura della facciata contrasta spettacolarmente con la vegetazione circostante.',
  '3 bedrooms and cabinet': '3 camere da letto e armadio',
  'invest and profit': 'Reddito passivo',
  'passive income': 'investi & ottieni',
  'annual passive income from long-term renting out': 'Passivo annuo',
  'annual passive income from daily rentals': 'Reddito annuale a lungo termine',
  'expected price increase after completion of construction': 'Aumento dei prezzi previsto dopo il completamento della costruzione',
  'Why choose Bali for property investment': 'Perché scegliere Bali per gli investimenti immobiliari',
  'Residential complex': 'Complesso residenziale',
  'Real estate': 'Immobiliare',
  'Price': 'Prezzo',
  'Apartments': 'Appartamenti',
  'bedroom': 'camera da letto',
  'bedrooms': 'camere da letto',
  'Villa': 'Villa',
  'Layouts': 'Piano:',
  'Terrace': 'Terrazza',
  'Installment plan': 'Tempo di costruzione:',
  GUEST_HOUSE_INVESTMENT_DESCRIPTION: 'Ingresso minimo a partire da 5000$ - 1/7 del costo di una Guest House',
  APARTMENTS_INVESTMENT_DESCRIPTION: 'Ingresso minimo è il 10% del costo di un Appartamento',
  VILLA_INVESTMENT_DESCRIPTION: 'Ingresso minimo è il 10% del costo di una Villa',
  'ROI:': 'ROI:',
  ABOUT_ESTATE_GUEST_HOUSES: 'guest houses',
  '2nd floor': '2° piano',
  '2nd floor of 3': '2° piano di 3',
  apartment: 'appartamento',
  '3rd floor': '3° piano',
  '3rd floor of 3': '3° piano di 3',
  '1st floor of 3': '1° piano di 3',
}