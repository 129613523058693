export default {
  START: 'Anfang:',
  'pre-sale': 'Vorverkauf ab 25.09.2023',
  'от 8 до 14 месяцев': 'von 8 bis 14 Monaten',
  'First payment': 'Erste Zahlung:',
  'от $5 000': 'ab $5 000',
  'about project': 'Über das Projekt',
  'PROJECT_DESCRIPTION': 'Oasis by PARQ ist eine moderne Anlage mit bester Infrastruktur auf Bali, die auf 37 Hektar Fläche liegt und 560 Infrastruktureinrichtungen umfassen wird, nämlich:',
  villas: 'Villen',
  apartments: 'Apartments',
  'guest houses': 'Gästehäuser',
  'guest house': 'Gästehaus',
  'Payment is made in cryptocurrency': '*Zahlung erfolgt in Kryptowährung',
  'Area:': 'Fläche:',
  'Area': 'Fläche',
  '1-3 floor of 3': '1-3 Stock von 3',
  'Ceiling height': 'Deckenhöhe:',
  'Laundry': 'Wäscherei',
  'Garden': 'Garten',
  'Description': 'Beschreibung:',
  'GUEST_HOUSE_DESCRIPTION': 'Das ideale kleine Wohnformat für Touristen. Die Fassadenveredelung kontrastiert wunderschön mit der umgebenden Grünfläche.',
  'Investment scheme': 'Investitionsschema',
  'Minimum entry from $5 000 - 1/7th of the cost of a guest house': 'Mindesteinstieg ab $5 000 - 1/7 der Kosten für ein Gästehaus',
  '1st floor': '1 Stock',
  'Private pool': 'Privater Pool',
  'Private entrance': 'Privater Eingang',
  'Minimum admission is 10% of the value of the dwelling': 'Mindestzulassung beträgt 10% des Wertes der Wohnung',
  'Plot area': 'Grundstücksfläche',
  'VILLA_150_DESCRIPTION': 'Elegantes mittelgroßes Wohnformat, bei dem die Fassadenveredelung spektakulär mit der umgebenden Grünfläche kontrastiert.',
  villa: 'Villa',
  VILLA_100_DESCRIPTION: 'Elegantes mittelgroßes Wohnformat, bei dem die Fassadenveredelung spektakulär mit der umgebenden Grünfläche kontrastiert.',
  '1 living room': '1 Wohnzimmer',
  '2 bedrooms': '2 Schlafzimmer',
  'Contact Us': 'Kontaktiere uns',
  'Phone': 'Telefon',
  'Email': 'E-Mail',
  '6-8 p.': '6-8 Stunden',
  'Placement': 'Platzierung',
  '3 bedrooms': '3 Schlafzimmer',
  'VILLA_200_DESCRIPTION': 'Elegantes mittelgroßes Wohnformat, bei dem die Fassadenveredelung spektakulär mit der umgebenden Grünfläche kontrastiert.',
  '3 bedrooms and cabinet': '3 Schlafzimmer und Kabinett',
  'invest and profit': 'Investieren und profitieren',
  'passive income': 'Passives Einkommen',
  'annual passive income from long-term renting out': 'Jährliches passives Einkommen aus langfristiger Vermietung',
  'annual passive income from daily rentals': 'Jährliches passives Einkommen aus täglicher Vermietung',
  'expected price increase after completion of construction': 'Erwartete Preissteigerung nach Fertigstellung des Baus',
  'Why choose Bali for property investment': 'Warum Bali für Immobilieninvestitionen wählen',
  'Residential complex': 'Wohnanlage',
  'Real estate': 'Immobilien',
  'Price': 'Preis',
  'Apartments': 'Wohnungen',
  'bedroom': 'Schlafzimmer',
  'bedrooms': 'Schlafzimmer',
  'Villa': 'Villa',
  'Layouts': 'Layouts:',
  'Terrace': 'Terrasse',
  'Installment plan': 'Ratenzahlungsplan:',
  GUEST_HOUSE_INVESTMENT_DESCRIPTION: 'Mindesteinstieg ab $5 000 - 1/7 der Kosten für ein Gästehaus',
  APARTMENTS_INVESTMENT_DESCRIPTION: 'Die Mindestaufnahme beträgt 10 % des Wertes der Wohnung',
  VILLA_INVESTMENT_DESCRIPTION: 'Die Mindestaufnahme beträgt 10 % des Wertes der Wohnung',
  'ROI:': 'ROI:',
  ABOUT_ESTATE_GUEST_HOUSES: 'gästehäuser',
  '2nd floor': '2. stock',
  '2nd floor of 3': '2. Stock von 3',
  apartment: 'wohnung',
  '3rd floor': '3. stock',
  '3rd floor of 3': '3. Stock von 3',
  '1st floor of 3': '1. Stock von 3',
}
