export default {
  START: 'Start presale:',
  'pre-sale': '25.09.2023',
  'от 8 до 14 месяцев': 'od 8 do 14 miesięcy',
  'First payment': 'Inwestycja minimalna:',
  'от $5 000': 'od $5 000',
  'about project': 'O projekcie',
  'PROJECT_DESCRIPTION': 'Oasis by PARQ to najnowocześniejszy projekt z najlepszą infrastrukturą na Bali, ulokowany na 37 hektarach, który obejmie 560 obiektów infrastrukturalnych, a dokładniej:',
  villas: 'wille',
  apartments: 'apartamenty',
  'guest houses': 'domki gościnne',
  'guest house': 'dom gościnny',
  'Payment is made in cryptocurrency': '*Płatność jest dokonywana w kryptowalucie',
  'Area:': 'Powierzchnia:',
  'Area': 'Powierzchnia',
  '1-3 floor of 3': '1-3 piętro z 3',
  'Ceiling height': 'Wysokość sufitu:',
  'Laundry': 'Pralnia',
  'Garden': 'Ogród',
  'Description': 'Opis:',
  'GUEST_HOUSE_DESCRIPTION': 'Idealny format zakwaterowania na małą skalę dla turystów. Wykończenia elewacji wyglądają spektakularnie w kontraście z otaczającą zielenią.',
  'Investment scheme': 'Program inwestycyjny',
  'Minimum entry from $5 000 - 1/7th of the cost of a guest house': 'Minimalna wpłata od $5 000 - 1/7 kosztu domu gościnnego',
  '1st floor': '1 piętro',
  'Private pool': 'Prywatny basen',
  'Private entrance': 'Oddzielne wejście',
  'Minimum admission is 10% of the value of the dwelling': 'Minimalna wpłata wynosi 10% wartości mieszkania',
  'Plot area': 'Powierzchnia działki',
  'VILLA_150_DESCRIPTION': 'Elegancki średni format mieszkania, gdzie wykończenie elewacji imponująco kontrastuje z otaczającą zielenią.',
  villa: 'willa',
  VILLA_100_DESCRIPTION: 'Elegancki średni format mieszkania, gdzie wykończenie elewacji imponująco kontrastuje z otaczającą zielenią.',
  '1 living room': '1 salon',
  '2 bedrooms': '2 sypialnie',
  'Contact Us': 'Skontaktuj się z nami',
  'Phone': 'Telefon',
  'Email': 'E-mail',
  '6-8 p.': '6-8 osób',
  'Placement': 'Umieszczenie',
  '3 bedrooms': '3 sypialnie',
  'VILLA_200_DESCRIPTION': 'Elegancki średni format mieszkania, gdzie wykończenie elewacji imponująco kontrastuje z otaczającą zielenią.',
  '3 bedrooms and cabinet': '3 sypialnie i gabinet',
  'invest and profit': 'Inwestuj i zyskuj',
  'passive income': 'Dochód pasywny',
  'annual passive income from long-term renting out': 'Roczny dochód pasywny z wynajmu długoterminowego',
  'annual passive income from daily rentals': 'Roczny dochód pasywny z wynajmu krótkoterminowego',
  'expected price increase after completion of construction': 'Oczekiwany wzrost ceny po zakończeniu budowy',
  'Why choose Bali for property investment': 'Dlaczego wybrać Bali na inwestycję w nieruchomości',
  'Residential complex': 'Kompleks mieszkalny',
  'Real estate': 'Nieruchomość',
  'Price': 'Cena',
  'Apartments': 'Apartamenty',
  'bedroom': 'sypialnia',
  'bedrooms': 'sypialnie',
  'Villa': 'Willa',
  'Layouts': 'Układy:',
  'Terrace': 'Taras',
  'Installment plan': 'Okres trwania budowy:',
  GUEST_HOUSE_INVESTMENT_DESCRIPTION: 'Minimalna wpłata od $5 000 - 1/7 część domu gościnnego',
  APARTMENTS_INVESTMENT_DESCRIPTION: 'Minimalna wpłata wynosi 10% wartości mieszkania',
  VILLA_INVESTMENT_DESCRIPTION: 'Minimalna wpłata wynosi 10% wartości mieszkania',
  'ROI:': 'Roczna rentowność:',
  ABOUT_ESTATE_GUEST_HOUSES: 'pokojów hotelowych(Dom gościnny)',
  '2nd floor': '2 piętro',
  '2nd floor of 3': '2 piętro z 3',
  apartment: 'apartamenty',
  '3rd floor': '3 piętro',
  '3rd floor of 3': '3 piętro z 3',
  '1st floor of 3': '1 piętro z 3',
}