import pl from './pl'
import de from './de'
import it from './it'
import en from './en'

export default {
  en,
  pl,
  de,
  it,
}